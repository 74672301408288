import { Link } from "gatsby"
import React from "react"
import { LargeButton } from "../layout/LargeButton"

interface Props {
    buttonConfig: ButtonConfig
}

export type ButtonConfig = {
    size: ButtonSize
    colour: ButtonColour
    ctaLabel: string
    ctaLink: string
    externalLink?: boolean
    isHTMLButton?: boolean
    eventFunctions?: {
        onClick?: (e: any) => void
    }
    className?: string
    id: string
    ariaLabel?: string
}

export enum ButtonSize {
    LARGE = "large",
    MEDIUM = "medium",
}

export enum ButtonColour {
    WHITE = "white",
    BLUE = "blue",
    BLACK = "black",
}

export const Button: React.FC<Props> = ({ buttonConfig }) => {
    if (buttonConfig.isHTMLButton) {
        return (
            <button
                className={`button button-${buttonConfig.colour} button-${buttonConfig.size
                    } ${buttonConfig.className ?? ""}`}
                type={buttonConfig.ctaLink as any}
                {...buttonConfig.eventFunctions}
            >
                <LargeButton
                    id={buttonConfig.id}
                    isBlue={buttonConfig.colour === ButtonColour.BLUE}
                />
                <h2>{buttonConfig.ctaLabel}</h2>
            </button>
        )
    }

    if (buttonConfig.externalLink) {
        return (
            <a
                aria-label={buttonConfig.ariaLabel}
                className={`button button-${buttonConfig.colour} button-${buttonConfig.size} ${buttonConfig.className}`}
                rel="noopener noreferrer"
                href={buttonConfig.ctaLink}
                target="_blank"
            >
                <LargeButton
                    id={buttonConfig.id}
                    isBlue={buttonConfig.colour === ButtonColour.BLUE}
                />
                <h2>{buttonConfig.ctaLabel}</h2>
            </a>
        )
    }

    return (
        <Link
            aria-label={buttonConfig.ariaLabel}
            className={`button button-${buttonConfig.colour} button-${buttonConfig.size} ${buttonConfig.className}`}
            to={buttonConfig.ctaLink}
        >
            <LargeButton
                id={buttonConfig.id}
                isBlue={buttonConfig.colour === ButtonColour.BLUE}
            />
            <h2>{buttonConfig.ctaLabel}</h2>
        </Link>
    )
}
